<template>
  <div class="support-wrapper">
    <Nav nums='5' isColor='#fff'/>
    <div class="support-content-wrapper">
      <div class="content-wrapper">
        <div class="support-line-title">全国客服中心热线电话：028-61680687</div>
        <div class="lines-wrapper">
          <div class="line-column">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
          <div class="line-column">
            <!-- <div class="line">四川国药老肯医疗灭菌有限公司 <a href="tel:://028-87805081">028-87805081</a></div> -->
            <!-- <div class="line">广西老肯灭菌技术有限公司 <a href="tel:://0771-4801729">0771-4801729</a></div> -->
          </div>
        </div>
        <div class="leave-a-message-wrapper">
          <!-- <a href="/#leaveWrod" title="#leaveWrod">去留言</a> -->
          <router-link to="/?active=leaveWrod">去留言</router-link>
        </div>
      </div>
    </div>
    <Floor/>
  </div>
</template>

<script>
import NavMixin from '@/mixins/nav-minxin'

export default {
  mixins: [
    NavMixin
  ],
  name: 'Support',
  beforeMount(){
    scrollTo(0, 0)
  }
}
</script>

<style lang="less" scoped>
@import '../fn.less';
html,body{
  height: 100%;
}
.support-wrapper {
  @import "../../style/common.less";
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .support-content-wrapper {
    flex: 1;
    padding-bottom: 13.025vw;
    position: relative;
      background: url('../../assets/support/bg.png') no-repeat;
      background-size: 100% 100%;
    .content-wrapper {
      padding: 20% 0 0 17%;
      .support-line-title {
        font-size: 1.5625vw;
        font-family:PuHuiTi;
        font-weight: bold;
        color: #101218;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          left: -1.04166vw;
          top: 50%;
          width: 0.625vw;
          height: 0.625vw;
          transform: translateY(-50%) rotateZ(-45deg);
          background: linear-gradient(135deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 50%, #85b9e5);
        }
      }
      .lines-wrapper {
        margin-top: 3.125vw;
        text-align: left;
        padding-bottom: 1.04166vw;
        display: flex;
        .line-column {
          padding-right: 2.916666vw;
          .line {
            font-size: 1.04166vw;
            font-family:PuHuiTi;
            font-weight: 400;
            color: #101218;
            height: 2.291666vw;
            a {
              color: black;
            }
          }
        }
      }
      .leave-a-message-wrapper {
        margin-top: 2.604166vw;
        a {
          text-decoration: none;
          color: white;
          display: inline-block;
          padding: 0.529166vw 1.3229166vw;
          background: #00538D;
          border-radius: 1.40625vw;
          transition: .25s;
          font-size: 0.84166vw;
          &:hover {
            // transform: scale(1.2);
            opacity: .7;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 980px) {
  .support-wrapper {
  @import "../../style/common.less";
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .support-content-wrapper {
    flex: 1;
    margin-bottom: 0;
    position: relative;
    background: url('../../assets/support/bg.png') no-repeat;
    background-size: cover;
    background-position: 25% 50%;
    .content-wrapper {
      padding: 40% 0 0 17%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      .support-line-title {
        .vvw(font-size,40);
        font-family: PuHuiTi;
        position: relative;
        margin-bottom: 3vw;
         &::before {
          content: '';
          position: absolute;
          left: -4vw;
          top: 50%;
          width: 2.625vw;
          height: 2.625vw;
          transform: translateY(-50%) rotateZ(-45deg);
          background: linear-gradient(135deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 50%, #85b9e5);
        }
      }
    }
    .content-wrapper{
       .lines-wrapper {
        margin-top: 0;
        text-align: left;
        padding-bottom: 1.04166vw;
        display: flex;
        flex-direction: column;
        height: 35vw;
        box-sizing: border-box;
        .line-column {
          padding-right: 2.916666vw;
          box-sizing: border-box;
          .line {
           .vvw(font-size,24);
            font-family:PuHuiTi;
            font-weight: 400;
            color: #101218;
            margin-bottom: 5vw;
            a {
           .vvw(font-size,24);
              color: black;
            }
          }
        }
      }
       .leave-a-message-wrapper {
        margin-top: 0;
        a {
          text-decoration: none;
          color: white;
          display: inline-block;
          padding: 0.529166vw 1.3229166vw;
          background: #00538D;
          border-radius: 1.40625vw;
          transition: .25s;
          .vvw(font-size,24);
        }
      }
    }
  }
}
  }
</style>
